<template>
  <div class="home-banner">
    <div class="home-banner__container">
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
      <ul class="home-banner__list">
        <li
          class="home-banner__item"
          v-for="(line, index) in $t('lineText')"
          :key="index"
        >
          {{ line.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
};
</script>
